import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import DecoupledDocument from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es.js';
import CommentForm from '@/components/contract/agreement/createComment/CommentForm.vue';
import LegalReplyBox from '@/components/contract/agreement/elements/LegalReplyBox/LegalReplyBox.vue';
import moment from 'moment';

//import htmlx from '@/views/dashboard/editor/template.js';

export default {
	name: 'AgreementEditor',
	props: {},
	data: () => ({
		editor: DecoupledDocument,
		editorData: '',
		editorConfig: {
			language: 'es',
			toolbar: {
				items: [],
			},
			allowedContent: true, // Permitir todo el contenido
			//extraAllowedContent: '*(*)[data-*]',
			extraAllowedContent: 'span[data-id]',
		},

		loadingData: false,
		loadingSave: false,
		msgError: [],
		position: {},
		textSelected: null,

		valid: null,
		enableObservationForm: false,
		observationForm: {
			content: null,
			description: null,
			agreement_notification_id: null,
		},
		requiredRule: [(v) => !!v || 'Campo requerido'],
		widthFloating: '',
		agreement: null,
	}),
	computed: {
		...mapState('agreement', ['getTemplate']),
		...mapState('agreementObservation', ['agreementObservations']),
		agreementId() {
			return this.$route.params?.agreementId || null;
		},

		hasObservationPending() {
			let hasObservation = false;
			if (Array.isArray(this.agreementObservations)) {
				hasObservation = this.agreementObservations.some(
					(e) => e.flag_solved == false
				);
			}
			return hasObservation;
		},

		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Legal',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'ContractHome',
					},
				},
				{
					text: 'Precontrato',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'ContractReadAgreement',
						params: {
							agreementId: this.$route?.params?.agreementId,
						},
					},
				},
				{
					text: 'Editor',
					disabled: true,
					href: '/',
				},
			];
		},

		styleFloating() {
			const isMobile = ['xs', 'sm'].includes(this.$vuetify.breakpoint.name);
			const bottom = isMobile ? '0' : 'initial';
			const maxHeight = isMobile ? '30vh' : '75vh';
			return {
				width: '100%',
				maxWidth: this.widthFloating,
				position: 'fixed',
				boxSizing: 'border-box',
				maxHeight,
				bottom,
			};
		},

		isRepplyLegal() {
			let isEnable = false;
			const agreementStatus = this.agreement?.status;
			const userSessionId = this.$store.state.auth?.user?.id;
			const nextUserApprover =
				this.agreement?.lastNotification?.next_user_approver_id;

			if (agreementStatus === 'APPROVED_SUPPLY') {
				isEnable = true;
			} else if (
				agreementStatus === 'PREAPPROVED_LEGAL' &&
				userSessionId == nextUserApprover
			) {
				isEnable = true;
			}
			return isEnable;
		},
	},
	watch: {},
	created() {
		this.readAgreement();
		this.listenResize();
	},

	mounted() {},

	methods: {
		...mapActions('agreement', [
			'readAgreementDocument',
			'cleanAgreement',
			'createAgreementWord',
			'updateAgreementNotification',
		]),

		...mapActions('agreementObservation', [
			'createAgreementObservation',
			'listAgreementObservation',
			'solvedAgreementObservation',
		]),

		listenResize() {
			const that = this;
			window.addEventListener(
				'resize',
				function () {
					that.setWidthFloating();
				},
				true
			);
		},

		setWidthFloating() {
			this.$nextTick(() => {
				let wrapper = this.$refs?.wrapperFloating;
				if (wrapper) {
					let that = this;
					setTimeout(function () {
						that.widthFloating = wrapper?.offsetWidth - 24 + 'px';
					}, 1);
				}
			});
		},

		async readAgreement() {
			if (this.agreementId) {
				this.loadingData = true;
				const { ok, response, error } = await this.readAgreementDocument({
					agreementId: this.agreementId,
					with_values_format: true,
				});
				if (ok) {
					this.agreement = response?.data;
					this.editorData =
						response?.data?.last_document_format_with_values || '';
					this.loadListen();
					this.getObservations();
					this.setWidthFloating();
				} else {
					let msm = error?.data?.message;
					msm = Array.isArray(msm) ? msm.join(', ') : msm;
					this.showAlert('error', msm);
				}
				this.loadingData = false;
			}
		},

		showAlert(color = '', msm = '') {
			$EventBus.$emit('showSnack', color || 'success', msm);
		},

		onReady(editor) {
			// Insert the toolbar before the editable area.
			editor.ui
				.getEditableElement()
				.parentElement.insertBefore(
					editor.ui.view.toolbar.element,
					editor.ui.getEditableElement()
				);
		},

		async saveAgreement() {
			this.loadingSave = true;
			this.msgError = [];
			const data = {
				new_content: this.editorData,
				agreement_id: parseInt(this.agreementId),
			};
			const { ok, response, error } = await this.createAgreementWord(data);
			if (ok) {
				$EventBus.$emit('showSnack', 'success', response?.message[0]);
				this.$router.push({
					name: 'ReadAgreement',
					params: {
						agreementId: this.agreementId,
					},
				});
			} else {
				const msm = error?.data?.message;
				this.msgError = Array.isArray(msm) ? msm : [msm];
			}
			this.loadingSave = false;
		},

		async copyToClipboard(keyword) {
			try {
				await navigator.clipboard.writeText('{{' + keyword + '}}');
				setTimeout(() => {
					this.showAlert('success', 'Keyword Copiada');
				}, 100);
			} catch (error) {
				this.showAlert('success', 'Error al copiar la Keyword');
			}
		},

		dragStart(event, key) {
			event.dataTransfer.setData('text/plain', '{{' + key + '}}');
		},

		drop() {},

		loadListen() {
			const that = this;
			let divEditor = document.getElementById('editor-wrapper');
			divEditor.addEventListener('mouseup', function () {
				that.textSelected = that.getSelectionText();
			});
		},

		getSelectionText() {
			let text = '';
			if (
				window.getSelection &&
				this.$route?.name == 'ContractAgreementObservations'
			) {
				text = window.getSelection().toString();
				const data = {
					general: window.getSelection().focusNode?.data,
					palabra: getSelection().toString(),
					inicio: getSelection().baseOffset,
					fin: getSelection().focusOffset,
					base: getSelection().baseNode.parentNode.innerHTML,
				};
				if (data.inicio > data.fin) {
					data.inicio = getSelection().focusOffset;
					data.fin = getSelection().baseOffset;
				}
				if (
					!window.getSelection().getRangeAt(0)?.commonAncestorContainer
						?.innerHTML
				) {
					let positionParf = this.editorData.indexOf(data.general);
					if (positionParf == -1) {
						positionParf = this.editorData.indexOf(data.base);
					}
					this.position = {
						start: positionParf + data.inicio,
						end: positionParf + data.inicio + data.palabra.length,
						total: this.editorData.length,
						text: data.palabra,
					};
				}
			}
			return text;
		},

		openFormObservation() {
			this.observationForm.description = this.textSelected;
			this.enableObservationForm = true;
		},

		cleanFormObservation() {
			this.$nextTick(() => {
				this.observationForm = {
					content: null,
					description: null,
					agreement_notification_id: null,
				};
				this.enableObservationForm = false;
				if (this.$refs.observationForm) this.$refs.observationForm.reset();
			});
		},

		async sendObservation() {
			const isvalidated = this.$refs.observationForm.validate();
			if (isvalidated) {
				this.observationForm.agreement_notification_id =
					this.agreement?.lastNotification?.id;
				const { ok, response, error } = await this.createAgreementObservation(
					this.observationForm
				);
				if (ok) {
					this.showSnack(response?.message);
					//this.addMarket(response?.data?.id);
					this.cleanFormObservation();
					this.getObservations();
				} else {
					this.showSnack(error?.data?.message, 'error');
				}
			}
		},

		showSnack(message = [], color = 'success') {
			message = Array.isArray(message) ? message.join('<br> ') : message;
			$EventBus.$emit('showSnack', color, message);
		},

		addMarket(/*idObservation*/) {
			// if (this.position.start) {
			// 	const data =
			// 		this.editorData.substring(0, this.position.start) +
			// 		`<span class="ck-comment-marker" data-comment="${idObservation}">▄</span>` +
			// 		this.editorData.substring(this.position.start, this.position.total);
			// 	console.log(data);
			// 	this.editorData = data;
			// 	//this.editorData.substring(this.position.end, this.position.total);
			// }
			if (this.position.start && this.position.end) {
				// this.editorData =
				// this.editorData.substring(0, this.position.start) +
				// `<span style="background-color:black;" data-foo="123" class="pin">${this.position?.text}</span>` +
				// this.editorData.substring(this.position.end, this.position.total);

				this.editorData = `<span style="background-color:black;" data-id="123" class="pin">${this.position?.text}</span>`;
			}
		},

		async getObservations() {
			const { ok, error } = await this.listAgreementObservation({
				agreement_id: this.agreement?.id,
			});
			if (!ok) {
				this.showSnack(error?.data?.message, 'error');
			}
		},

		async solvedObservation(id) {
			const { ok, response, error } = await this.solvedAgreementObservation({
				observationId: id,
			});
			if (ok) {
				this.showSnack(response?.message);
				this.getObservations();
			} else {
				this.showSnack(error?.data?.message, 'error');
			}
		},

		formatDate(date) {
			if (!isNaN(new Date(date))) {
				moment.locale('es');
				return date ? moment(date).format('DD/MM/YY LT') : null;
			} else {
				return null;
			}
		},

		cutText(texto = '', longitudMaxima = 15) {
			if (texto && texto.length > longitudMaxima) {
				return texto.slice(0, longitudMaxima) + '...';
			} else {
				return texto;
			}
		},
	},
	beforeDestroy() {
		this.cleanAgreement();
	},
	components: {
		CommentForm,
		LegalReplyBox,
	},
};
